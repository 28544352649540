import { CATEGORY_VIEW } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;

    const {
        categoryIds,
        category: {
            id, name, product_count
        }
    } = instance.props;

    const {
        breadcrumbsWereUpdated
    } = instance.state;

    const {
        category: {
            id: prevId
        }
    } = prevProps;

    const categoryChange = id !== prevId || (!breadcrumbsWereUpdated && id === categoryIds);

    if (categoryChange) {
        // const isSubcategory = url_path.search('/');
        // if (isSubcategory !== -1) {
        //     eventTrack(SUBCATEGORY_VIEW, {
        //         'Category Name': name,
        //         'Category Id': id,
        //         'Item Count': product_count
        //     });
        // }

        eventTrack(CATEGORY_VIEW, {
            'Category Name': name,
            'Category Id': id,
            'Item Count': product_count
        });
    }
};

export default {
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
