/* eslint-disable fp/no-let */
import { QUICK_PRODUCT_VIEWED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;

    const {
        payload,
        product: {
            sku
        }
    } = instance.props;

    const {
        product: {
            sku: prevSku
        }
    } = prevProps;

    if (payload != null) {
        if (sku !== prevSku) {
            const {
                product: {
                    id, name,
                    price_range: {
                        minimum_price: {
                            final_price: { value, currency }
                        }
                    },
                    image: { url },
                    categories
                }
            } = payload;

            let categoryName = '';
            categories.forEach(({ name }) => {
                categoryName = categoryName.concat(categoryName.length > 0 ? ', ' : '', name);
            });

            let categoryIds = '';
            categories.forEach(({ id }) => {
                categoryIds = categoryIds.concat(categoryIds.length > 0 ? ', ' : '', id);
            });

            eventTrack(QUICK_PRODUCT_VIEWED, {
                Product_id: id,
                Product_name: name,
                price: value,
                currency,
                image: url,
                'category name': categoryName,
                'Category ID': categoryIds
            });
        }
    }
};

export default {
    'Scandipwa/Component/QuickViewPopup/Container/QuickViewPopupContainer': {
        'member-function': {
            componentDidUpdate
        }
    }
};
