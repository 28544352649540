import { STORE_VIEWED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const renderStoreDetails = (args, callback, instance) => {
    const { handleStoreDetails } = instance.props;

    const {
        store_name,
        state,
        city
    } = handleStoreDetails();

    if (store_name) {
        eventTrack(STORE_VIEWED, {
            State: state,
            City: city,
            'Store Name': store_name
        });
    }

    return callback(...args);
};

export default {
    'Storefinder/Route/StoreDetails/Component/StoreDetailsComponent': {
        'member-function': {
            renderStoreDetails
        }
    }
};
