import getStore from 'Util/Store';

import { VIEW_LIST_BY } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const onChange = (args, callback, instance) => {
    const [value] = args;

    const { selectOptions } = instance.props;
    const state = getStore().getState();
    const {
        category: {
            id, name, product_count
        }
    } = state.CategoryReducer;

    const selectedLabel = selectOptions.filter((item) => (item.value === value ? item : null));

    const option = selectedLabel.reduce((acc, a) => (a.label ? acc + a.label : acc), []);

    eventTrack(VIEW_LIST_BY, {
        'List Type': option,
        'Category Name': name,
        'Category ID': id,
        'Item Count': product_count
    });

    callback(...args);
};

export default {
    'Component/CategorySort/Component': {
        'member-function': {
            onChange
        }
    }
};
