import { LICENSE_CODE } from '../config';

const render = (args, callback) => {
    window.webengage.init(LICENSE_CODE);
    return callback(...args);
};

export default {
    'Component/App/Component': {
        'member-function': {
            render
        }
    }
};
