import { VIEW_STORE_CREDIT } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

class MyAccountStoreCreditComponent {
    renderCurrentCreditBalance = (args, callback, instance) => {
        const { storeCreditBalance: { amount: { value } } } = instance.props;

        eventTrack(VIEW_STORE_CREDIT, {
            'Current Credit Balance': value
        });

        return callback(...args);
    };
}

const { renderCurrentCreditBalance } = new MyAccountStoreCreditComponent();

export default {
    'Scandipwa/Component/MyAccountStoreCredit/Component/MyAccountStoreCreditComponent': {
        'member-function': {
            renderCurrentCreditBalance
        }
    }
};
