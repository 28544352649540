import { CONFIGURABLE, SIMPLE } from '@scandipwa/scandipwa/src/util/Product/Types';

import { NOT_APPLICABLE } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    getBrand,
    getCategories, getPrice, getProductName, getVariant
} from './baseProduct.data';

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionOne */
export const getDimensionOne = (type_id, product, configurableVariantIndex, mainSku) => {
    if (type_id !== CONFIGURABLE) {
        return NOT_APPLICABLE;
    }

    if (!configurableVariantIndex && mainSku && type_id === CONFIGURABLE) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku).name;
    }

    if (!isNaN(configurableVariantIndex)) {
        const {
            name = ''
        } = product.variants[configurableVariantIndex];

        return name;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionTwo */
export const getDimensionTwo = (type_id, product, configurableVariantIndex, inQtyChange = false, mainSku) => {
    if (type_id !== CONFIGURABLE) {
        return NOT_APPLICABLE;
    }

    if (!isNaN(configurableVariantIndex)) {
        if (inQtyChange) {
            return mainSku;
        }
        const {
            sku
        } = product.variants[configurableVariantIndex];

        return sku;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getCustomDimensionsQtyChange */
export const getCustomDimensionsQtyChange = (item) => {
    const { sku: actionSku, product: { variants = [], dimensions, type_id } } = item;

    if (type_id === SIMPLE) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const correctVarint = variants.find(({ sku }) => sku === actionSku);
        const custom = correctVarint.dimensions;

        return custom ? JSON.parse(custom) : null;
    }

    return null;
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getCustomDimensions */
export const getCustomDimensions = (product, confIndex) => {
    const {
        type_id = SIMPLE, dimensions, variants
    } = product;

    if (type_id === SIMPLE) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const custom = variants[confIndex].dimensions;
        return custom ? JSON.parse(custom) : null;
    }

    return null;
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productAddToCart */
export const productAddToCart = ({ product, quantity = 0, configurableVariantIndex = false }) => {
    const {
        type_id = SIMPLE, categories, sku, price = false
    } = product;

    return {
        name: getProductName(type_id, product),
        id: sku,
        price: getPrice(product, type_id, price),
        category: getCategories(categories),
        brand: getBrand(product),
        variant: getVariant(product, type_id, configurableVariantIndex),
        quantity,
        ...getCustomDimensions(product, configurableVariantIndex)
    };
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productQtyChangeData */
export const productQtyChangeData = ({ product: item, configurableVariantIndex = false }) => {
    const {
        qty = false, price, product: {
            type_id = SIMPLE, categories, sku: parentSku, name: parentName, variants
        }, product
    } = item;

    return {
        name: parentName,
        id: parentSku,
        price: (type_id === CONFIGURABLE)
            ? getPrice(variants[0], type_id, price, false)
            : getPrice(product, type_id, price, false),
        category: getCategories(categories),
        brand: getBrand(product),
        variant: getVariant(product, type_id, configurableVariantIndex),
        quantity: Math.abs(qty),
        ...getCustomDimensionsQtyChange(item)
    };
};
