/* eslint-disable no-unused-vars */
import getStore from 'Util/Store';

import { REMOVED_FROM_WISHLIST, WISHLIST_UPDATED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';
import { getWishlistProducts } from '../helper/Wishlist';

export const removeItem = (args, callback, instance) => {
    const { product } = instance.props;

    return callback(...args).then(() => {
        const {
            name,
            price_range: {
                minimum_price: {
                    final_price: { value },
                    regular_price: { value: retail_price },
                    discount: { amount_off }
                }
            },
            wishlist: { options = [] }
        } = product;

        eventTrack(REMOVED_FROM_WISHLIST, {
            'Product Name': name,
            'Retail Price': retail_price,
            Color: options.length === 2 ? options[0].value : '',
            Size: options.length === 2 ? options[1].value : '',
            Discount: amount_off,
            Price: value
        });

        const state = getStore().getState();
        const { productsInWishlist } = state.WishlistReducer;
        const wishlistTotal = Object.entries(productsInWishlist).reduce((acc, [key, val]) => {
            const { price_range: { minimum_price: { final_price: { value } } } } = val;

            return acc + value;
        }, 0);

        const wishlistProduct = Object.entries(productsInWishlist).map(getWishlistProducts);
        eventTrack(WISHLIST_UPDATED, {
            'Total Amount': wishlistTotal,
            'No. Of Products': wishlistProduct.length,
            'Product Details': wishlistProduct
        });
    });
};

export default {
    'Component/WishlistItem/Container': {
        'member-function': {
            removeItem
        }
    }
};
