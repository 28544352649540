/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
    PRODUCT_VIEWED
} from '../config';
import { getAttribute } from '../helper/Product';
import { eventTrack } from '../helper/WebEngage/Event';

export const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;

    const {
        product,
        product: {
            sku, attributes
        }
    } = instance.props;

    const {
        product: {
            sku: prevSku
        }
    } = prevProps;

    if (sku !== prevSku) {
        const {
            id, name,
            price_range: {
                minimum_price: {
                    final_price: { value, currency },
                    regular_price: { value: retail_price },
                    discount: { amount_off }
                }
            },
            image: { url },
            categories
        } = product;

        const allAttribsWithValues = Object.entries(attributes).reduce((acc, [key, val]) => {
            const { attribute_label, attribute_value } = val;
            if (attribute_value) {
                return { ...acc, [attribute_label]: val };
            }

            return acc;
        }, {});

        const country = getAttribute(allAttribsWithValues, 'country_of_origin');
        const material = getAttribute(allAttribsWithValues, 'material');
        const style = getAttribute(allAttribsWithValues, 'product_style');
        const collection = getAttribute(allAttribsWithValues, 'collectionnew');

        let categoryName = '';
        categories.forEach(({ name }) => {
            categoryName = categoryName.concat(categoryName.length > 0 ? ', ' : '', name);
        });

        let categoryIds = '';
        categories.forEach(({ id }) => {
            categoryIds = categoryIds.concat(categoryIds.length > 0 ? ', ' : '', id);
        });

        eventTrack(PRODUCT_VIEWED, {
            Product_id: id,
            Product_name: name,
            'Retail Price': retail_price,
            Discount: amount_off,
            Price: value,
            Currency: currency,
            image: url,
            Material: material.label !== undefined ? material.label : '',
            Style: style.label !== undefined ? style.label : '',
            'Country of Origin': country.label !== undefined ? country.label : '',
            'Collection Type': collection.label !== undefined ? collection.label : '',
            'Category Name': categoryName,
            'Category ID': categoryIds
        });
    }
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
