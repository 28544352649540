/* eslint-disable max-lines */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import getStore from 'Util/Store';

import {
    ADD_TO_WISHLIST,
    CART_UPDATED, COUPON_CODE_APPLIED, COUPON_CODE_FAILED, REMOVED_FROM_CART, WISHLIST_UPDATED
} from '../config';
import { getCartProductsDetailsInArray } from '../helper/Cart';
import {
    getAttribute, getCartProductColorSize, getProductCategoriesDataInString, getWishlistProductColorSize
} from '../helper/Product';
import { eventTrack } from '../helper/WebEngage/Event';
import { getWishlistProducts } from '../helper/Wishlist';

class CartItemContainer {
    // changeItemQty = (args, callback, _instance) => callback(...args)
    //     .then(
    //         () => {
    //             const state = getStore().getState();
    //             const cartData = state.CartReducer.cartTotals;
    //             const { items = [], subtotal } = cartData;

    //             eventTrack(CART_UPDATED, {
    //                 'No. of Products': items.length,
    //                 'Total Amount': subtotal
    //             });
    //         }
    //     );

    applyCouponToCart = (args, callback, _instance) => {
        callback(...args)
            .then(() => {
                const state = getStore().getState();
                const cartData = state.CartReducer.cartTotals;
                const {
                    subtotal, subtotal_with_discount, discount_amount, coupon_code
                } = cartData;
                const [dispatch, couponCode] = args;

                if (coupon_code !== null) {
                    eventTrack(COUPON_CODE_APPLIED, {
                        'Cart Value Before Discount': subtotal,
                        'Cart Value After Discount': subtotal_with_discount,
                        'Coupon Code': coupon_code,
                        'Discount Amount': discount_amount
                    });
                    dispatch(showPopup('', {}));
                } else {
                    eventTrack(COUPON_CODE_FAILED, {
                        'Coupon Code': couponCode
                    });
                }
            });
    };

    handleRemoveItem = (args, callback, instance) => {
        callback(...args);

        const {
            item: {
                product: {
                    id, name, attributes, categories,
                    stock_item: { in_stock },
                    price_range: {
                        minimum_price: {
                            regular_price: { value },
                            discount: { amount_off }
                        }
                    }
                }, qty, price
            }, currency_code, item
        } = instance.props;

        const allAttribsWithValues = Object.entries(attributes).reduce((acc, [key, val]) => {
            const { attribute_label, attribute_value } = val;
            if (attribute_value) {
                return { ...acc, [attribute_label]: val };
            }

            return acc;
        }, {});

        const colorSize = getCartProductColorSize(item);
        const country = getAttribute(allAttribsWithValues, 'country_of_origin');
        const material = getAttribute(allAttribsWithValues, 'material');
        const style = getAttribute(allAttribsWithValues, 'product_style');
        const collection = getAttribute(allAttribsWithValues, 'collectionnew');

        let categoryName = '';
        categories.forEach(({ name }) => {
            categoryName = categoryName.concat(categoryName.length > 0 ? ', ' : '', name);
        });

        let categoryIds = '';
        categories.forEach(({ id }) => {
            categoryIds = categoryIds.concat(categoryIds.length > 0 ? ', ' : '', id);
        });

        eventTrack(REMOVED_FROM_CART, {
            'Product Id': id,
            'Product Name': name,
            Size: colorSize.size,
            Color: colorSize.color,
            Quantity: qty,
            In_Stock_Status: in_stock,
            'Retail Price': value,
            Discount: amount_off,
            Price: price,
            Currency: currency_code,
            Material: material.label !== undefined ? material.label : '',
            Style: style.label !== undefined ? style.label : '',
            'Country of Origin': country.label !== undefined ? country.label : '',
            'Collection Type': collection.label !== undefined ? collection.label : '',
            'Category Name': categoryName,
            'Category ID': categoryIds
        });
    };

    removeProductAndUpdateCrossSell = (args, callback, instance) => callback(...args).then(() => {
        const state = getStore().getState();
        const cartData = state.CartReducer.cartTotals;
        const { items = [], subtotal } = cartData;

        eventTrack(CART_UPDATED, {
            'No. of Products': items.length,
            'Total Amount': subtotal,
            'Product Details': getCartProductsDetailsInArray(items)
        });
    });

    handleMoveToWishListItem = (args, callback, instance) => {
        callback(...args);

        const {
            item: { product }
        } = instance.props;

        if (isSignedIn()) {
            const {
                id, name, stock_item: { in_stock }, attributes, sku,
                price_range: {
                    minimum_price: {
                        final_price: { value, currency },
                        regular_price: { value: retail_price },
                        discount: { amount_off }
                    }
                }, image: { url }, categories
            } = product;

            const allAttribsWithValues = Object.entries(attributes).reduce((acc, [key, val]) => {
                const { attribute_label, attribute_value } = val;
                if (attribute_value) {
                    return { ...acc, [attribute_label]: val };
                }

                return acc;
            }, {});

            const colorSize = getWishlistProductColorSize(product);
            const country = getAttribute(allAttribsWithValues, 'country_of_origin');
            const material = getAttribute(allAttribsWithValues, 'material');
            const style = getAttribute(allAttribsWithValues, 'product_style');
            const collection = getAttribute(allAttribsWithValues, 'collectionnew');

            // const max = Math.max(...Object.keys(instance.props.productsInWishlist));
            // console.log('max', max);
            // const { wishlist: { options = [] } } = instance.props.productsInWishlist[max];

            let categoryName = '';
            categories.forEach(({ name }) => {
                categoryName = categoryName.concat(categoryName.length > 0 ? ', ' : '', name);
            });

            let categoryIds = '';
            categories.forEach(({ id }) => {
                categoryIds = categoryIds.concat(categoryIds.length > 0 ? ', ' : '', id);
            });

            eventTrack(ADD_TO_WISHLIST, {
                'Product Id': id,
                Size: colorSize.size,
                Color: colorSize.color,
                'Product Name': name,
                In_Stock_Status: in_stock,
                'Retail Price': retail_price,
                Discount: amount_off,
                Price: value,
                Currency: currency,
                image: url,
                Material: material.label !== undefined ? material.label : '',
                Style: style.label !== undefined ? style.label : '',
                'Country of Origin': country.label !== undefined ? country.label : '',
                'Collection Type': collection.label !== undefined ? collection.label : '',
                'Category Name': categoryName,
                'Category ID': categoryIds
            });

            const { productsInWishlist } = instance.props;
            var wishlistProduct = [];
            var wishlistTotal = 0;
            if (Object.entries(productsInWishlist).length > 0) {
                wishlistTotal = Object.entries(productsInWishlist).reduce((acc, [key, val]) => {
                    const { price_range: { minimum_price: { final_price: { value } } } } = val;

                    return acc + value;
                }, 0);

                wishlistProduct = Object.entries(productsInWishlist).map(getWishlistProducts);
            }

            if (wishlistProduct.length > 0) {
                wishlistProduct.push({
                    id,
                    name,
                    sku,
                    options: [{ label: 'Color', value: colorSize.color }, { label: 'Size', value: colorSize.size }],
                    image: url
                });
            } else {
                wishlistProduct[0] = {
                    id,
                    name,
                    sku,
                    options: [{ label: 'Color', value: colorSize.color }, { label: 'Size', value: colorSize.size }],
                    image: url
                };
            }

            eventTrack(WISHLIST_UPDATED, {
                'Total Amount': wishlistTotal + value,
                'No. Of Products': wishlistProduct.length,
                'Product Details': wishlistProduct
            });
        }
    };
}

const {
    // changeItemQty,
    applyCouponToCart, handleRemoveItem, handleMoveToWishListItem, removeProductAndUpdateCrossSell
} = new CartItemContainer();

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            // changeItemQty,
            applyCouponToCart
        }
    },
    'Component/CartItem/Container': {
        'member-function': {
            handleRemoveItem,
            handleMoveToWishListItem,
            removeProductAndUpdateCrossSell
        }
    }
};
