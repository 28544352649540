import { setAttribute } from '../helper/WebEngage/Event';

export const onCustomerSave = (args, callback, instance) => callback(...args).then(() => {
    const { customer: { dob, gender } } = instance.props;

    const genderArr = [{
        id: 0,
        label: 'Male',
        value: 1
    }, {
        id: 1,
        label: 'Female',
        value: 2
    },
    {
        id: 2,
        label: 'Others',
        value: 814
    }];

    const [genderFiltered] = genderArr.filter((i) => i.value === gender);

    setAttribute('birth_date', dob);
    setAttribute('gender', genderFiltered.label.toLowerCase());
});

export default {
    'Scandipwa/Component/MyAccountPersonalInfo/Container/MyAccountPersonalInfoContainer': {
        'member-function': {
            onCustomerSave
        }
    }
};
