import {
    ORDER_CANCELLED
} from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const onCancelOrderClick = (args, callback, instance) => {
    callback(...args);
    const { payload: { order: { grand_total } } } = instance.props;

    eventTrack(ORDER_CANCELLED, {
        'Order Amount': grand_total
    });
};

export default {
    'Scandipwa/Component/MyAccountCancelOrderPopup/Container/MyAccountCancelOrderPopupContainer': {
        'member-function': {
            onCancelOrderClick
        }
    }
};
