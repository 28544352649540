import PropTypes from 'prop-types';

import FieldInput from 'Component/FieldInput';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';

import {
    CHECKBOX_TYPE,
    DATE_TYPE,
    EMAIL_TYPE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXTAREA_TYPE
} from './Field.config';

import './Field.override.style';

/** @namespace Scandipwa/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceField {
    static propTypes = {
        prepone: PropTypes.string
    };

    renderTypeText() {
        const { prepone } = this.props;
        return (
            <>
            { prepone ? <span>+91</span> : null }
            <FieldInput
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              type="text"
            />
            </>
        );
    }

    renderTypeDate() {
        return (
            <FieldInput
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              type="date"
            />
        );
    }

    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case EMAIL_TYPE:
            return this.renderTypeEmail();
        case DATE_TYPE:
            return this.renderTypeDate();
        case FILE_TYPE:
            return this.renderFile();
        default:
            return this.renderTypeText();
        }
    }
}

export default FieldComponent;
