/* eslint-disable fp/no-let */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

import { ADD_TO_WISHLIST, REMOVED_FROM_WISHLIST, WISHLIST_UPDATED } from '../config';
import { getAttribute } from '../helper/Product';
import { eventTrack } from '../helper/WebEngage/Event';
import { getWishlistProducts } from '../helper/Wishlist';

export const toggleProductInWishlist = (args, callback, instance) => {
    const [add] = args;
    const {
        product
    } = instance.props;

    if (isSignedIn()) {
        return callback(...args).then(() => {
            const {
                id, name, stock_item: { in_stock }, attributes,
                price_range: {
                    minimum_price: {
                        final_price: { value, currency },
                        regular_price: { value: retail_price },
                        discount: { amount_off }
                    }
                }, image: { url }, categories
            } = product;

            const allAttribsWithValues = Object.entries(attributes).reduce((acc, [key, val]) => {
                const { attribute_label, attribute_value } = val;
                if (attribute_value) {
                    return { ...acc, [attribute_label]: val };
                }

                return acc;
            }, {});

            const country = getAttribute(allAttribsWithValues, 'country_of_origin');
            const material = getAttribute(allAttribsWithValues, 'material');
            const style = getAttribute(allAttribsWithValues, 'product_style');
            const collection = getAttribute(allAttribsWithValues, 'collectionnew');

            const { productsInWishlist } = instance.props;

            const wishlistTotal = Object.entries(productsInWishlist).reduce((acc, [key, val]) => {
                const { price_range: { minimum_price: { final_price: { value } } } } = val;

                return acc + value;
            }, 0);

            const wishlistProduct = Object.entries(productsInWishlist).map(getWishlistProducts);

            if (add) {
                const max = Math.max(...Object.keys(instance.props.productsInWishlist));
                const { wishlist: { options = [] } } = instance.props.productsInWishlist[max];

                let categoryName = '';
                categories.forEach(({ name }) => {
                    categoryName = categoryName.concat(categoryName.length > 0 ? ', ' : '', name);
                });

                let categoryIds = '';
                categories.forEach(({ id }) => {
                    categoryIds = categoryIds.concat(categoryIds.length > 0 ? ', ' : '', id);
                });

                eventTrack(ADD_TO_WISHLIST, {
                    'Product Id': id,
                    Color: options.length === 2 ? options[0].value : '',
                    Size: options.length === 2 ? options[1].value : '',
                    'Product Name': name,
                    In_Stock_Status: in_stock,
                    'Retail Price': retail_price,
                    Discount: amount_off,
                    Price: value,
                    Currency: currency,
                    image: url,
                    Material: material.label !== undefined ? material.label : '',
                    Style: style.label !== undefined ? style.label : '',
                    'Country of Origin': country.label !== undefined ? country.label : '',
                    'Collection Type': collection.label !== undefined ? collection.label : '',
                    'Category Name': categoryName,
                    'Category ID': categoryIds
                });
            } else {
                const COLOR = 'color';
                const SIZE = 'size';

                const color = getQueryParam(COLOR, history.location);
                const size = getQueryParam(SIZE, history.location);

                var actualSize = '';
                var actualColor = '';

                if (color && size) {
                    const { product: { attributes } } = instance.props;
                    const {
                        [COLOR]: {
                            attribute_options: {
                                [color]: { label: selectedColor }
                            }
                        }, [SIZE]: {
                            attribute_options: {
                                [size]: { label: selectedSize }
                            }
                        }
                    } = attributes;

                    actualSize = selectedSize;
                    actualColor = selectedColor;
                }
                eventTrack(REMOVED_FROM_WISHLIST, {
                    'Product Name': name,
                    Color: actualColor,
                    Size: actualSize,
                    'Retail Price': retail_price,
                    Discount: amount_off,
                    Price: value
                });
            }

            eventTrack(WISHLIST_UPDATED, {
                'Total Amount': wishlistTotal,
                'No. Of Products': wishlistProduct.length,
                'Product Details': wishlistProduct
            });
        });
    }

    return callback(...args);
};

export default {
    'Component/ProductWishlistButton/Container': {
        'member-function': {
            toggleProductInWishlist
        }
    }
};
