/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
import { ADD_TO_BAG, CART_UPDATED } from '../config';
import { getCartProductsDetailsInArray } from '../helper/Cart';
import { getAttribute, getProductColorSize } from '../helper/Product';
import { eventTrack } from '../helper/WebEngage/Event';

export const aroundAfterAddToCart = (args, callback, instance) => {
    const {
        product: {
            name, id, discount, attributes,
            stock_item: { in_stock },
            price_range: {
                minimum_price: {
                    regular_price: { value: retail_price },
                    final_price: { currency, value: price }
                }
            },
            image: { url }, categories
        }, quantity, product, cartTotals: { items, subtotal }
    } = instance.props;

    const allAttribsWithValues = Object.entries(attributes).reduce((acc, [key, val]) => {
        const { attribute_label, attribute_value } = val;
        if (attribute_value) {
            return { ...acc, [attribute_label]: val };
        }

        return acc;
    }, {});

    const colorSize = getProductColorSize(items, product);
    const country = getAttribute(allAttribsWithValues, 'country_of_origin');
    const material = getAttribute(allAttribsWithValues, 'material');
    const style = getAttribute(allAttribsWithValues, 'product_style');
    const collection = getAttribute(allAttribsWithValues, 'collectionnew');

    let categoryName = '';
    categories.forEach(({ name }) => {
        categoryName = categoryName.concat(categoryName.length > 0 ? ', ' : '', name);
    });

    let categoryIds = '';
    categories.forEach(({ id }) => {
        categoryIds = categoryIds.concat(categoryIds.length > 0 ? ', ' : '', id);
    });

    eventTrack(ADD_TO_BAG, {
        'Product Name': name,
        'Product Id': id,
        Size: colorSize.size,
        Color: colorSize.color,
        Quantity: quantity,
        'In Stock Status': in_stock,
        'Retail Price': retail_price,
        Discount: discount,
        Price: price,
        Currency: currency,
        Image: url,
        Material: material.label !== undefined ? material.label : '',
        Style: style.label !== undefined ? style.label : '',
        'Country of Origin': country.label !== undefined ? country.label : '',
        'Collection Type': collection.label !== undefined ? collection.label : '',
        'Category Name': categoryName,
        'Category ID': categoryIds
    });

    eventTrack(CART_UPDATED, {
        'No. of Products': items.length,
        'Total Amount': subtotal,
        'Product Details': getCartProductsDetailsInArray(items)
    });

    return callback(...args);
};

export default {
    'Component/AddToCart/Container': {
        'member-function': {
            afterAddToCart: aroundAfterAddToCart
        }
    }
};
