import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

import OtpQuery from '../../query/Otp.query';
import MyAccountCreateAccount from './MyAccountCreateAccount.component';
import {
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_VERIFY_OTP
} from './MyAccountCreateAccount.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    createAccountVerify: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createAccountVerify(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
    // TODO extend mapDispatchToProps
});

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    containerFunctions = {
        onCreateAccountAttempt: this.onCreateAccountAttempt.bind(this),
        handleCreateAccountOTP: this.handleCreateAccountOTP.bind(this),
        handleCreateAccountOTPVerify: this.handleCreateAccountOTPVerify.bind(this),
        handleResendOTP: this.handleResendOTP.bind(this),
        handleRetry: this.handleRetry.bind(this)
    };

    state = {
        currentCreateRenderForm: CREATE_ACCOUNT,
        formFields: {},
        count: 0,
        resendCount: 0,
        isRetryForm: false
    };

    containerProps() {
        const {
            state,
            handleSignIn,
            showTaxVatNumber,
            newsletterActive
        } = this.props;

        const { currentCreateRenderForm, formFields, isRetryForm } = this.state;

        return {
            state,
            handleSignIn,
            showTaxVatNumber,
            newsletterActive,
            currentCreateRenderForm,
            formFields,
            isRetryForm
        };
    }

    async handleCreateAccountOTP(fields) {
        const {
            showNotification,
            setLoadingState
        } = this.props;

        try {
            const mutation = OtpQuery.getCreateAccountOTP(fields.mobileNumber);
            await fetchMutation(mutation);

            this.setState({
                currentCreateRenderForm: CREATE_ACCOUNT_VERIFY_OTP,
                formFields: fields
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        setLoadingState(false);
    }

    async handleCreateAccountOTPVerify(fields) {
        const {
            createAccountVerify,
            showNotification,
            setLoadingState,
            onSignIn
        } = this.props;

        try {
            const mutation = OtpQuery.getCreateAccountOTPVerify(fields.mobileNumber, fields.otp);
            await fetchQuery(mutation);

            // eslint-disable-next-line no-unused-vars
            const { confirm_password, ...customerData } = fields;
            await createAccountVerify(customerData);
            onSignIn();
        } catch (error) {
            showNotification('error', getErrorMessage(error));

            this.setState((prevState) => ({
                count: prevState.count + 1
            }), () => {
                const { count } = this.state;
                // eslint-disable-next-line no-magic-numbers
                if (count >= 3) {
                    this.setState({
                        currentCreateRenderForm: CREATE_ACCOUNT,
                        formFields: {},
                        count: 0
                    });
                }
            });
        }

        setLoadingState(false);
    }

    async handleResendOTP() {
        const {
            showNotification,
            setLoadingState
        } = this.props;

        const fields = this.state.formFields;

        try {
            const mutation = OtpQuery.getCreateAccountOTP(fields.mobileNumber);
            await fetchMutation(mutation);

            this.setState({ count: 0 });

            this.setState((prevState) => ({
                resendCount: prevState.resendCount + 1
            }), () => {
                const { resendCount } = this.state;
                // eslint-disable-next-line no-magic-numbers
                if (resendCount >= 3) {
                    this.setState({
                        isRetryForm: true,
                        formFields: {},
                        resendCount: 0
                    });
                }
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        setLoadingState(false);
    }

    onCreateAccountAttempt(_, invalidFields) {
        const { showNotification, setLoadingState } = this.props;

        if (invalidFields) {
            showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
        }

        setLoadingState(!invalidFields);
        this.setState({ isSubmitted: true });
    }

    handleRetry(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();

        this.setState({
            currentCreateRenderForm: CREATE_ACCOUNT,
            formFields: {},
            count: 0,
            resendCount: 0
        });
    }

    render() {
        return (
            <MyAccountCreateAccount
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
