/* eslint-disable eqeqeq */
import { isSignedIn } from 'Util/Auth';
import getStore from 'Util/Store';

// import { getGuestQuoteId } from 'Util/Cart';
import { CHECKOUT_COMPLETED, CHECKOUT_STARTED, SHIPPING_DETAILS_UPDATED } from '../config';
import { getCartProductsIdString, getCartProductsNameString } from '../helper/Cart';
import { eventTrack, setAttribute } from '../helper/WebEngage/Event';

export const handleCheckoutClick = (args, callback, instance) => {
    const {
        totals,
        guest_checkout
    } = instance.props;

    const {
        subtotal, coupon_code, discount_amount, grand_total, items = []
    } = totals;

    const hasOutOfStockProductsInCart = instance.hasOutOfStockProductsInCartItems(totals.items);

    if (!hasOutOfStockProductsInCart && (guest_checkout || isSignedIn())) {
        eventTrack(CHECKOUT_STARTED, {
            'Product Name': getCartProductsNameString(items),
            'Product Id': getCartProductsIdString(items),
            'No. Of Products': items.length,
            'Total Amount': grand_total,
            'Discount Amount': discount_amount,
            'Coupon Code': coupon_code === null ? '' : coupon_code,
            'Sub Total': subtotal
        });
    }

    callback(...args);
};

export const onCheckoutButtonClick = (args, callback, instance) => {
    const {
        totals,
        guest_checkout
    } = instance.props;

    const {
        subtotal, coupon_code, discount_amount, grand_total, items = []
    } = totals;

    const hasOutOfStockProductsInCart = instance.hasOutOfStockProductsInCartItems(totals.items);

    if (!hasOutOfStockProductsInCart && (guest_checkout || isSignedIn())) {
        eventTrack(CHECKOUT_STARTED, {
            'Product Name': getCartProductsNameString(items),
            'Product Id': getCartProductsIdString(items),
            'No. Of Products': items.length,
            'Total Amount': grand_total,
            'Discount Amount': discount_amount,
            'Coupon Code': coupon_code === null ? '' : coupon_code,
            'Sub Total': subtotal
        });
    }
    callback(...args);
};

export const savePaymentMethodAndPlaceOrder = (args, callback, instance) => {
    const [paymentInformation] = args;
    const { paymentMethod: { code } } = paymentInformation;
    // const isCustomerSignedIn = isSignedIn();
    // const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

    const {
        totals
    } = instance.props;

    const {
        subtotal, coupon_code, discount_amount, grand_total, tax_amount, shipping_amount, items = []
    } = totals;

    callback(...args).then(() => {
        const { orderID } = instance.state;

        eventTrack(CHECKOUT_COMPLETED, {
            'Order Id': orderID,
            'Payment Mode': code,
            'Product Name': getCartProductsNameString(items),
            'Product Id': getCartProductsIdString(items),
            'No. Of Products': items.length,
            'Total Amount': grand_total,
            'Discount Amount': discount_amount,
            'Coupon Code': coupon_code === null ? '' : coupon_code,
            'Sub Total': subtotal,
            'Shipping Charges': shipping_amount,
            'Tax Charged': tax_amount
        });
    });
};

export const saveAddressInformation = (args, callback) => {
    callback(...args).then(() => {
        const [addressInformation] = args;

        const {
            shipping_address: {
                street, city, country_id, postcode, region_id
            }
        } = addressInformation;

        const state = getStore().getState();
        const { countries } = state.ConfigReducer;

        const country = countries.map(({ id, label, available_regions }) => (
            id === country_id ? { label, available_regions } : null
        ));

        const [region] = country[0].available_regions.filter(({ id }) => (
            id == region_id
        ));

        eventTrack(SHIPPING_DETAILS_UPDATED, {
            'Shipping Address': street,
            City: city,
            State: region.name,
            Country: country[0].label
        });

        setAttribute('postal_code', postcode);
        setAttribute('city', city);
        setAttribute('country', country[0].label);
    });
};

export default {
    'Component/CartOverlay/Container': {
        'member-function': {
            handleCheckoutClick
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            onCheckoutButtonClick
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder, saveAddressInformation
        }
    }
};
