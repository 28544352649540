/* eslint-disable fp/no-let */
import { VIEW_SHOPPING_BAG } from '../config';
import { getCartProductsNameString } from '../helper/Cart';
import { eventTrack } from '../helper/WebEngage/Event';

// export const renderCartItems = (args, callback, instance) => {
//     const {
//         totals: {
//             items = [],
//             subtotal
//         },
//         activeOverlay
//     } = instance.props;

//     if (items.length > 0 && activeOverlay !== '') {
//         eventTrack(VIEW_SHOPPING_BAG, {
//             'Total Items': items.length,
//             Subtotal: subtotal,
//             'Product Name': getCartProductsNameString(items)
//         });
//     }

//     return callback(...args);
// };

export const componentDidUpdate = (args, callback, instance) => {
    callback(...args);
    const {
        totals: {
            items = [],
            subtotal
        },
        isCartItemLoading,
        activeOverlay
    } = instance.props;

    const [prevProps] = args;

    const {
        totals: {
            subtotal: prevSubtotal
        }
    } = prevProps;

    if ((items.length > 0 && activeOverlay !== '') && prevSubtotal === subtotal && !isCartItemLoading) {
        eventTrack(VIEW_SHOPPING_BAG, {
            'Total Items': items.length,
            Subtotal: subtotal,
            'Product Name': getCartProductsNameString(items)
        });
    }
};

export default {
    'Component/CartOverlay/Component': {
        'member-function': {
            // renderCartItems,
            componentDidUpdate
        }
    }
};
