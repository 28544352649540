/* eslint-disable no-unused-vars */
/* eslint-disable fp/no-let */
/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsNameString */
export const getCartProductsNameString = (items) => {
    let productsName = '';
    items.forEach(({ product }) => {
        productsName = productsName.concat(productsName.length > 0 ? ', ' : '', product.name);
    });

    return productsName;
};

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsIdString */
export const getCartProductsIdString = (items) => {
    let Ids = '';
    items.forEach(({ product }) => {
        Ids = Ids.concat(Ids.length > 0 ? ', ' : '', product.id);
    });

    return Ids;
};

/** @namespace Ambab/Webengage/Helper/Cart/Index/getCartProductsDetailsInArray */
export const getCartProductsDetailsInArray = (items) => Object.entries(items).map(([key, val], i) => {
    const { product } = val;

    const [variant] = product.variants;
    return {
        id: variant.id, name: variant.name, image: variant.image.url, sku: variant.sku
    };
});
