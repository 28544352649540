/* eslint-disable eqeqeq */
import getStore from 'Util/Store';

import {
    SHIPPING_DETAILS_UPDATED
} from '../config';
import { eventTrack, setAttribute } from '../helper/WebEngage/Event';

export function handleAddress(args, callback) {
    const [address] = args;

    const {
        country_id, street, city, postcode, default_shipping, region: { region_id }
    } = address;

    if (default_shipping) {
        const state = getStore().getState();
        const { countries } = state.ConfigReducer;

        const country = countries.map(({ id, label, available_regions }) => (
            id === country_id ? { label, available_regions } : null
        ));

        const [region] = country[0].available_regions.filter(({ id }) => (
            id == region_id
        ));

        eventTrack(SHIPPING_DETAILS_UPDATED, {
            'Shipping Address': street,
            City: city,
            State: region.name,
            Country: country[0].label
        });

        setAttribute('postal_code', postcode);
        setAttribute('city', city);
        setAttribute('country', country[0].label);
    }

    return callback(...args);
}

export default {
    'Component/MyAccountAddressPopup/Container': {
        'member-function': {
            handleAddress
        }
    }
};
