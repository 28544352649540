import { getMobileWithISD } from 'Util/Helper';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Otp/Query/OtpQuery */
export class OtpQuery {
    getQuery() {
        // TODO implement query
    }

    getCreateAccountOTP(mobileNumber) {
        const mutation = new Field('createAccountOTP')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(mobileNumber))
            .addFieldList([
                'message'
            ]);

        return mutation;
    }

    getCreateAccountOTPVerify(mobileNumber, otp) {
        // const { mobileNumber, otp } = options;
        const mutation = new Field('createAccountOTPVerify')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(mobileNumber))
            .addArgument('otp', 'String!', otp)
            .addFieldList([
                'message'
            ]);

        return mutation;
    }

    getCreateCustomerAccount(options) {
        const { mobileNumber, otp, ...input } = options;

        const mutation = new Field('createCustomerAccount')
            .addArgument('input', 'CustomerInput!', input)
            .addArgument('mobileNumber', 'String!', getMobileWithISD(mobileNumber))
            .addArgument('otp', 'String!', otp)
            .addFieldList([
                'message', 'token'
            ]);

        return mutation;
    }

    getLoginOTPQuery(mobileNumber) {
        const mutation = new Field('loginOTP')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(mobileNumber))
            .addFieldList([
                'message'
            ]);

        return mutation;
    }

    getLoginOTPVerifyQuery(options) {
        const { mobileNumber, otp } = options;
        const mutation = new Field('loginOTPVerify')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(mobileNumber))
            .addArgument('otp', 'String!', otp)
            .addFieldList([
                'message', 'token'
            ]);

        return mutation;
    }

    getForgotPasswordOTPQuery(mobileNumber) {
        const mutation = new Field('forgotPassworOTP')
            .addArgument('mobileNumber', 'String!', mobileNumber)
            .addFieldList([
                'message'
            ]);

        return mutation;
    }

    getForgotPasswordOTPVerifyQuery(mobileNumber, otp, websiteId) {
        const mutation = new Field('forgotPassworOTP')
            .addArgument('mobileNumber', 'String!', mobileNumber)
            .addArgument('otp', 'String!', otp)
            .addArgument('websiteId', 'String!', websiteId)
            .addFieldList([
                'message'
            ]);

        return mutation;
    }

    getResetPasswordOTP(mobileNumber, password, otp) {
        const mutation = new Field('resetPasswordOtp')
            .addArgument('mobileNumber', 'String!', mobileNumber)
            .addArgument('password', 'String!', password)
            .addArgument('mobileNumber', 'String!', otp)
            .addFieldList([
                'message'
            ]);

        return mutation;
    }
}

export default new OtpQuery();
