import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { isSignedIn } from 'Util/Auth';
import { roundPrice } from 'Util/Price';

import { getActionField } from '../../eventData/actionField.data';
import { productAddToCart as productAction, productQtyChangeData } from '../../eventData/actionProduct.data';
import { baseProductData } from '../../eventData/baseProduct.data';
import {
    getGeneralEventData, getGeneralPurchaseData, getPath, getProducts, getStoreView, routes, getPurchaseShippingData, getPurchaseProduct
} from '../../eventData/general.data';
import { getImpressionsData } from '../../eventData/impression.data';
import { setExecuted } from '../../store/GoogleTagManager/GoogleTagManager.action';
import {
    CHECKOUT, NO_RESULTS_FOUND, ONE, RESULTS_LOADED, ROOT, ZERO
} from './GoogleTagManager.config.js';
import {
    EVENT_GTM_CHECKOUT,
    EVENT_GTM_CHECKOUT_OPTION,
    EVENT_GTM_GENERAL_INIT,
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH,
    EVENT_GTM_NOT_FOUND,
    EVENT_GTM_PRODUCT_ADD_TO_CART,
    EVENT_GTM_PRODUCT_CLICK,
    EVENT_GTM_PRODUCT_DETAIL,
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART, EVENT_GTM_PURCHASE, EVENT_GTM_SITE_SEARCH, EVENT_GTM_SITE_SEARCH_STARTED,
    EVENT_GTM_USER_LOGIN,
    EVENT_GTM_USER_REGISTER,
    EVENT_KEY_ADD_TO_CART,
    EVENT_KEY_CHECKOUT,
    EVENT_KEY_CHECKOUT_OPTION,
    EVENT_KEY_GENERAL, EVENT_KEY_NOT_FOUND,
    EVENT_KEY_PRODUCT_CLICK,
    EVENT_KEY_PRODUCT_DETAIL,
    EVENT_KEY_PRODUCT_REMOVE_FROM_CART, EVENT_KEY_PURCHASE,
    EVENT_KEY_SEARCH, EVENT_KEY_SEARCH_STARTED,
    EVENT_KEY_USER_LOGIN,
    EVENT_KEY_USER_REGISTER,
    IMPRESSIONS
} from './GoogleTagManager.events';

/** @namespace GtmNew/Component/GoogleTagManager/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    config: state.GoogleTagManagerReducer.config,
    isRewriteLoading: state.UrlRewritesReducer.isLoading,
    storeConfig: state.ConfigReducer,
    isExecuted: state.GoogleTagManagerReducer.isExecuted,
    event: state.GoogleTagManagerReducer.event,
    events: state.GoogleTagManagerReducer.config.events,
    customData: state.GoogleTagManagerReducer.custom,
    productsOnPages: state.ProductListReducer.pages,
    areProductsLoading: state.ProductListReducer.isLoading,
    signedIn: state.MyAccountReducer.isSignedIn,
    isLoading: state.ProductListInfoReducer.isLoading,
    cart: state.CartReducer.cartTotals,
    store: state
});

/** @namespace GtmNew/Component/GoogleTagManager/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    executed: () => dispatch(setExecuted())
});

/** @namespace GtmNew/Component/GoogleTagManager/Container/GoogleTagManagerContainer */
export class GoogleTagManagerContainer extends PureComponent {
    static propTypes = {
        config: PropTypes.shape({
            enabled: PropTypes.bool,
            gtm_id: PropTypes.string
        }),
        isRewriteLoading: PropTypes.bool.isRequired,
        isExecuted: PropTypes.bool.isRequired,
        event: PropTypes.string,
        events: PropTypes.shape({}),
        executed: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        customData: PropTypes.any,
        // eslint-disable-next-line react/forbid-prop-types
        location: PropTypes.any.isRequired
    };

    static defaultProps = {
        config: {
            enabled: false,
            gtm_id: ''
        },
        event: EVENT_GTM_GENERAL_INIT,
        events: {},
        customData: ''
    };

    state = {
        isInitialized: false
    };

    eventMap = {
        [EVENT_GTM_GENERAL_INIT]: {
            getData: this.prepareGeneralData.bind(this),
            eventKey: EVENT_KEY_GENERAL
        },
        [EVENT_GTM_USER_LOGIN]: {
            getData: this.blankEvent.bind(this),
            eventKey: EVENT_KEY_USER_LOGIN
        },
        [EVENT_GTM_USER_REGISTER]: {
            getData: this.blankEvent.bind(this),
            eventKey: EVENT_KEY_USER_REGISTER
        },
        [EVENT_GTM_PRODUCT_ADD_TO_CART]: {
            getData: this.prepareAddToCartData.bind(this),
            eventKey: EVENT_KEY_ADD_TO_CART
        },
        [EVENT_GTM_PRODUCT_REMOVE_FROM_CART]: {
            getData: this.prepareRemoveFromCartData.bind(this),
            eventKey: EVENT_KEY_PRODUCT_REMOVE_FROM_CART
        },
        [EVENT_GTM_PRODUCT_CLICK]: {
            getData: this.prepareProductClickData.bind(this),
            eventKey: EVENT_KEY_PRODUCT_CLICK
        },
        [EVENT_GTM_PRODUCT_DETAIL]: {
            getData: this.prepareProductDetailsData.bind(this),
            eventKey: EVENT_KEY_PRODUCT_DETAIL
        },
        [EVENT_GTM_IMPRESSIONS_PLP]: {
            getData: this.prepareProductImpression.bind(this),
            eventKey: IMPRESSIONS
        },
        [EVENT_GTM_IMPRESSIONS_SEARCH]: {
            getData: this.prepareProductSearchImpression.bind(this),
            eventKey: IMPRESSIONS
        },
        [EVENT_GTM_NOT_FOUND]: {
            getData: this.prepareNotFound.bind(this),
            eventKey: EVENT_KEY_NOT_FOUND
        },
        [EVENT_GTM_CHECKOUT_OPTION]: {
            getData: this.prepareCheckoutOption.bind(this),
            eventKey: EVENT_KEY_CHECKOUT_OPTION
        },
        [EVENT_GTM_CHECKOUT]: {
            getData: this.prepareCheckout.bind(this),
            eventKey: EVENT_KEY_CHECKOUT
        },
        [EVENT_GTM_PURCHASE]: {
            getData: this.preparePurchase.bind(this),
            eventKey: EVENT_KEY_PURCHASE
        },
        [EVENT_GTM_SITE_SEARCH]: {
            getData: this.prepareSearch.bind(this),
            eventKey: EVENT_KEY_SEARCH
        },
        [EVENT_GTM_SITE_SEARCH_STARTED]: {
            getData: this.blankEvent.bind(this),
            eventKey: EVENT_KEY_SEARCH_STARTED
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    }

    componentDidUpdate(prevProps) {
        const {
            isExecuted, isRewriteLoading,
            config: { enabled }, store, location: { pathname }
        } = this.props;

        const {
            isInitialized
        } = this.state;

        const { isRewriteLoading: prevRewriteLoading, location: { pathname: prevPath } } = prevProps;

        const storeView = getStoreView(store);
        const path = getPath(pathname, storeView);

        // Init And General Event
        if (!isRewriteLoading && (isSignedIn() || this.canFireGeneral()) && !isInitialized && enabled) {
            this.init();
            this.fireGeneralEvent();
        }

        const shouldFireGeneral = ((prevRewriteLoading && !isRewriteLoading) && isInitialized && enabled);

        // General Event
        if (shouldFireGeneral) {
            this.fireGeneralEvent();
        }

        // If user Lands on one of React routes
        if (((pathname !== prevPath) && isInitialized && enabled) && !shouldFireGeneral) {
            routes().find((route) => {
                if (path.includes(route)) {
                    this.fireGeneralEvent();
                    return null;
                }
            });

            if (path === ROOT) {
                this.fireGeneralEvent();
            }
        }

        // Any event fire
        if (!isExecuted && isInitialized) {
            this.executeEvent();
        }
    }

    init() {
        const { config: { enabled, gtm_id } } = this.props;
        this.setState({ isInitialized: true });

        if (enabled) {
            TagManager.initialize({ gtmId: gtm_id });
        }
    }

    executeEvent() {
        const { events, event } = this.props;

        // Check if event exists in Event map and backend configuration
        if ((this.eventExist(event) && events[event])) {
            const data = this.eventMap[event].getData();

            this.pushEvent(data);
        }
    }

    canFireGeneral(prevSignedIn, event) {
        const { cart = {} } = this.props;

        return (!isSignedIn() && !(Object.keys(cart).length === 0));
    }

    fireGeneralEvent() {
        const data = this.prepareGeneralData();
        TagManager.dataLayer(data);
    }

    resize() {
        this.setState({ canFireImpression: true });
    }

    // Event Push
    pushEvent(data) {
        const { executed } = this.props;

        TagManager.dataLayer(data);
        // Changes redux state and tels that event is executed
        executed();
    }

    // Check if event exists in a map
    eventExist(event) {
        return typeof this.eventMap[event] !== 'undefined';
    }

    prepareCheckoutOption() {
        const { customData: { option, step } } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            option,
                            step,
                            action: CHECKOUT
                        }
                    }
                }
            }
        };
    }

    prepareCheckout() {
        const { customData: { step }, cart: { items = [] } } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    checkout: {
                        actionField: {
                            step,
                            action: CHECKOUT
                        },
                        products: items.length ? getProducts(items, CHECKOUT) : []
                    }
                }
            }
        };
    }

    prepareNotFound() {
        return {
            dataLayer: {
                event: this.getEventKey(),
                eventCategory: EVENT_KEY_NOT_FOUND,
                eventAction: window.location.href,
                eventLabel: "",
                eventNonInteraction: ONE
            }
        };
    }

    prepareUserLoginData() {
        return {
            dataLayer: {
                event: this.getEventKey()
            }
        };
    }

    prepareAddToCartData() {
        const { customData, storeConfig: { currencyData: { current_currency_code } } } = this.props;
        const { isCart = false } = customData;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    currencyCode: current_currency_code,
                    add: {
                        products: [!isCart ? { ...productAction(customData) }
                            : { ...productQtyChangeData(customData) }]
                    }
                }
            }
        };
    }

    prepareRemoveFromCartData() {
        const { customData, storeConfig: { currencyData: { current_currency_code } } } = this.props;
        const { isCart = false } = customData;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    currencyCode: current_currency_code,
                    remove: {
                        products: [!isCart ? { ...productAction(customData) }
                            : { ...productQtyChangeData(customData) }]
                    }
                }
            }
        };
    }

    prepareProductClickData() {
        const { customData: { prevCategoryId, pathname, search = false }, customData, store } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    click: {
                        actionField: getActionField(store, prevCategoryId, pathname, search),
                        products: [{ ...baseProductData({ product: { ...customData } }, true) }]
                    }
                }
            }
        };
    }

    prepareProductDetailsData() {
        const { customData } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    detail: {
                        products: [{ ...baseProductData({ product: { ...customData } }, true) }]
                    }
                }
            }
        };
    }

    blankEvent() {
        return {
            dataLayer: {
                event: this.getEventKey()
            }
        };
    }

    prepareGeneralData() {
        const { store } = this.props;

        return {
            dataLayer: {
                event: EVENT_KEY_GENERAL,
                ...getGeneralEventData(store)
            }
        };
    }

    preparePurchase() {
        const {
            customData: {
                orderPaymentMethod,
                orderShippingMethod,
                orderID,
                revenue,
                tax,
                shipping,
                coupon,
                products,
                shippingAddress,
                discount_amount,
                additional_data
            },
            storeConfig: { currencyData: { current_currency_code } }, store
        } = this.props;

        products.map((product) => {
            product.price = +roundPrice(product.price);
            return product;
        });

        return {
            dataLayer: {
                event: this.getEventKey(),
                orderPaymentMethod,
                orderShippingMethod,
                ...getPurchaseShippingData(shippingAddress,additional_data),
                discount_amount,
                ...getGeneralPurchaseData(store,additional_data),
                ecommerce: {
                    currencyCode: current_currency_code,
                    purchase: {
                        actionField: {
                            id: orderID,
                            revenue: +roundPrice(revenue),
                            tax: +roundPrice(tax),
                            coupon: coupon === null ? '' : coupon,
                            shipping: +roundPrice(shipping),
                            products: getPurchaseProduct(products)
                        }
                    }
                }
            }
        };
    }

    prepareProductImpression() {
        const {
            customData, storeConfig: { currencyData: { current_currency_code } }, store,
            location: {
                pathname
            }
        } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    currencyCode: current_currency_code,
                    impressions: getImpressionsData(customData, store)
                }
            }
        };
    }

    prepareProductSearchImpression() {
        const { customData: { products = [], search }, overlay = false } = this.props;
        const siteSearch = this.prepareSearch(search, products.length);
        TagManager.dataLayer(siteSearch);

        return this.prepareProductImpression();
    }

    prepareSearch(search, resultLoaded) {
        return {
            dataLayer: {
                event: EVENT_KEY_SEARCH,
                eventCategory: EVENT_KEY_SEARCH,
                eventAction: !resultLoaded ? NO_RESULTS_FOUND : RESULTS_LOADED,
                eventLabel: search,
                eventNonInteraction: ZERO
            }
        };
    }

    // gets event Key aka Event Name in dataLayer
    getEventKey() {
        const { event = '' } = this.props;

        return this.eventMap[event].eventKey;
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoogleTagManagerContainer));
