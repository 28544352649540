/* eslint-disable no-unused-vars */
import { CONFIGURABLE } from 'Util/Product';

export function getWishlistProduct(product) {
    const { url, type_id } = product;

    if (type_id !== CONFIGURABLE) {
        return product;
    }

    const wishedVariant = product.variants.find(({ sku }) => sku === product.wishlist.sku);

    if (!wishedVariant) {
        return {
            id: product.id,
            name: product.name,
            sku: product.sku,
            options: product.wishlist.options,
            image: product.image.url
        };
    }

    return {
        id: wishedVariant.id,
        name: wishedVariant.name,
        sku: wishedVariant.sku,
        options: product.wishlist.options,
        image: product.image.url
    };
}

/** @namespace Ambab/Webengage/Helper/Wishlist/Index/getWishlistProducts */
export const getWishlistProducts = ([id, product]) => getWishlistProduct(product);
