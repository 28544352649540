/* eslint-disable consistent-return */
import getStore from 'Util/Store';

import { CHECK_DELIVERY } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const checkZipCodeForProduct = (args, callback) => callback(...args).then((result) => {
    const state = getStore().getState();
    const { zipCode } = state.ZipCodeServiceabilityReducer;
    const {
        product: {
            stock_item: { in_stock }
        }
    } = state.ProductReducer;

    const {
        formatted_message, estimated_date, is_cod_available, is_serviceable
    } = result;

    if (!is_serviceable) {
        return result;
    }

    const workingDays = formatted_message.split('|');

    const dayRange = workingDays[0].match(/\d+/g);

    eventTrack(CHECK_DELIVERY, {
        'Pin Code': zipCode,
        In_Stock_Status: in_stock,
        'Cash On Delivery Available': is_cod_available,
        'Estimated Date': estimated_date,
        'Delivered in Working Days': `${dayRange[0]}-${dayRange[1]}`
    });

    return result;
});

export default {
    'Scandipwa/Store/ZipCodeServiceability/Dispatcher/ZipCodeServiceabilityDispatcher': {
        'member-function': {
            checkZipCodeForProduct
        }
    }
};
