// import { USER_LOGGED_IN } from '../config';
import getStore from 'Util/Store';

import { USER_SIGNED_UP } from '../config';
import {
    eventTrack, setAttribute, trackUserLogin, trackUserLogout
} from '../helper/WebEngage/Event';

export const signIn = (args, callback) => callback(...args).then(() => {
    const state = getStore().getState();
    const { customer: { id } } = state.MyAccountReducer;
    // const { customer: { mobilenumber, id } } = state.MyAccountReducer;

    // trackUserLogin(`${mobilenumber}-${id}`);
    trackUserLogin(id);
});

export const createAccountVerify = (args, callback) => callback(...args).then(() => {
    const state = getStore().getState();
    const {
        customer: {
            mobilenumber, firstname, lastname, email, id
        }
    } = state.MyAccountReducer;

    eventTrack(USER_SIGNED_UP, {
        'First Name': firstname,
        'Last Name': lastname,
        Mobile: mobilenumber,
        Email: email
    });

    // trackUserLogin(`${mobilenumber}-${id}`);
    trackUserLogin(id);

    setAttribute('first_name', firstname);
    setAttribute('last_name', lastname);
    setAttribute('phone', mobilenumber);
    setAttribute('email', email);
});

export const logout = (args, callback) => {
    callback(...args);
    trackUserLogout();
};
export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn, logout, createAccountVerify
        }
    }
};
