/* eslint-disable consistent-return */
import NewsletterEmailExistsQuery from '@ambab/webengage/src/query/NewsletterEmailExists.query';

import { fetchQuery } from 'Util/Request';

import { SIGNED_UP_FOR_NEWSLETTER } from '../config';
import { eventTrack, trackUserLogin } from '../helper/WebEngage/Event';

export const onFormSubmit = async (args, callback, instance) => {
    callback(...args);
    const [fields] = args;
    const {
        allowGuestSubscribe,
        isSignedIn
    } = instance.props;
    const { newsletterEmail: email } = fields;

    if (!allowGuestSubscribe && !isSignedIn) {
        return;
    }

    if (!isSignedIn) {
        const result = await fetchQuery(
            NewsletterEmailExistsQuery.getNewsLetterEmailCustomerIdQuery(email)
        );
        const { isEmailExist: { customer_id } } = result;

        if (customer_id !== null) {
            trackUserLogin(customer_id);
        }
    }

    eventTrack(SIGNED_UP_FOR_NEWSLETTER, {
        Email: email
    });
};

export default {
    'Component/NewsletterSubscription/Container': {
        'member-function': {
            onFormSubmit
        }
    }
};
